import './App.css';
import { Routes, Route, useLocation } from "react-router-dom";
import Home from './pages/home';
import Portfolio from './pages/portfolio';
import About from './pages/about';
import Contact from './pages/contact';
import Consultation from './pages/consultation';
import Blog from './pages/blog';
import Post from './pages/post';
import Team from './pages/team';
import Careers from './pages/careers';
import Job from './pages/job';
import Text_to_Image from './pages/text_to_image';
import AI_Chat from './pages/ai_chat';
import Services from './pages/services'; 
import Header from './components/Header';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';

function App() {

  useEffect(() => {
    AOS.init({
        duration: 800, // values from 0 to 3000, with step 50ms
        easing: 'ease-in-out', // default easing for AOS animations
        once: true,
        offset: 25, 

    });
})


const location = useLocation();

  return (
    <>
     <AnimatePresence mode='wait'>
     <Routes location={location} key={location.pathname}>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<div  data-aos="fade-up"><Portfolio /></div>} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/consultation" element={<Consultation />} />
        {/* <Route path="/blog" element={<Blog />} />
        <Route path="/post/:id" element={<Post />} />
        <Route path="/team" element={<Team />} /> */}
        <Route path="/careers" element={<Careers />} />
        <Route path="/job/:id" element={<Job />} />
        <Route path="/ai_tools/text_to_image" element={<Text_to_Image />} />
        <Route path="/ai_tools/ai_chat" element={<AI_Chat />} />
        <Route path="/services" element={<Services />} />
      </Routes>
     </AnimatePresence>
    </>
  );
}

export default App;
