import { useState } from 'react';
import './css/Header.css'
import { GoSearch } from "react-icons/go";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { SignedIn, SignedOut, SignInButton, UserButton , useUser } from "@clerk/clerk-react";
import Space from "../components/Space";
import { Link } from 'react-router-dom';



const Header = ({ backgroundColor, color, complete = true }) => {

    const { user, isLoaded, isSignedIn } = useUser();

    const [selectedTool, setSelectedTool] = useState('');

    const [show , setShow]  = useState(false);

    const handleToolChange = (tool) => {
        setSelectedTool(tool);
        // Navigate to the selected tool link
        if (tool === 'textToImage') {
            window.location.to = '/ai_tools/text_to_image'; // Replace this with the actual link for Text to Image
        } else if (tool === 'aiChat') {
            window.location.to = '/ai_tools/ai_chat'; // Replace this with the actual link for AI Chat
        }
    }


    const showSideBar = () => {

       
        setShow(true);
        
    }

    const hideSideBar = () => {

      
        setShow(false);
    }

    const showMenu = () => {

        const menu = document.querySelector('.dropdown-content');
        if (menu.style.display === 'block') menu.style.display = 'none';
        else menu.style.display = 'block';
    }

    const showMenu_sideBar = () => {

        const menu = document.querySelector('.sideBar-dropdown-content');
        if (menu.style.display === 'block') menu.style.display = 'none';
        else menu.style.display = 'block';
    }

    const sideBar=<ul data-aos-mirror="true" data-aos="slide-left" style={{ backgroundColor: "black", color: color }} className='sideBar head_shadow'>
    <li style={{paddingTop:"5vh"}} onClick={hideSideBar}><Link to='#'><svg fill={color} xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" /></svg></Link></li>
    <li><Link style={{ color: color }} to='/about'>About</Link></li>
    <li><Link style={{ color: color }} to='/portfolio'>Portfoilo</Link></li>
    {/* <li><Link style={{ color: color }} to='/blog'>Blog</Link></li> */}
    <li><Link style={{ color: color }} to='/contact'>Contact</Link></li>
    <li><Link style={{ color: color }} to='/careers'>Careers</Link></li>
  
    <li>
        <div className='sideBar-dropdown'>
            <button onClick={() => showMenu_sideBar()} className='sideBar-dropbtn' style={{ backgroundColor: "transparent", color: color }}>AI Tools
                <IoIosArrowDown size={10}></IoIosArrowDown>

            </button>
            <div className='sideBar-dropdown-content'>
                <Link style={{  marginBottom: '10px' }} to='/ai_tools/text_to_image'>Text to Image</Link>
                <Link style={{  marginBottom: '10px' }} to='/ai_tools/ai_chat'>AI Chat</Link>
            </div>
        </div>
    </li>

    {  !isSignedIn && <li><Link><SignInButton className='auth'></SignInButton></Link></li>}  
        { isSignedIn && <li><Link><UserButton></UserButton></Link></li> }
        <div style={{ height: "100vh", width: "100%" , zIndex:-3 , position:"fixed"}}><Space /> </div>

</ul>

    return (

        <div  data-aos="fade-up" data-aos-delay="500"     data-aos-duration="1000"
        data-aos-once="true" style={{ backgroundColor: backgroundColor, color: color }} className="Header">


            <nav>
                {show && sideBar}

                <ul style={{ backgroundColor: backgroundColor, color: color }}>
                    <li><Link style={{ marginLeft: "-4vw", color: color }} to='/home'>

                        <img width={225} src='/Logo.png'></img></Link></li>

                    <li className='hideOnMobile'><Link style={{ color: color }} to='/about'>About</Link></li>
                    <li className='hideOnMobile'><Link style={{ color: color }} to='/portfolio'>Portfoilo</Link></li>
                    <li className='hideOnMobile'><Link style={{ color: color }} to='/careers'>Careers</Link></li>
                    {/* <li className='hideOnMobile'><Link style={{ color: color }} to='/blog'>Blog</Link></li> */}
                    <li className='hideOnMobile'><Link style={{ color: color }} to='/contact'>Contact</Link></li>
                    <li>
                        <div className='dropdown'>
                            <button onClick={() => showMenu()} className='dropbtn' style={{ backgroundColor: "transparent", color: color }}>AI Tools
                                <IoIosArrowDown style={{ marginLeft: "10px" }} size={10}></IoIosArrowDown>

                            </button>
                            <div className='dropdown-content' style={{ backgroundColor: backgroundColor, color: color }}>
                                <Link to='/ai_tools/text_to_image' style={{  marginBottom: '10px' }} >Text to Image</Link>
                                <Link to='/ai_tools/ai_chat' style={{  marginBottom: '10px' }} >AI Chat</Link>
                            </div>
                        </div>
                    </li>
                    <div className='navbar-icons'>
                        {/* <li className='hideOnMobile'><Link style={{ color: color }}><GoSearch size={20}></GoSearch></Link></li> */}
                        {/* <li className='hideOnMobile'><Link style={{color: color }} to='#'><IoIosNotificationsOutline size={28}></IoIosNotificationsOutline></Link></li> */}
                        {  !isSignedIn && <li className='hideOnMobile'><Link><SignInButton className='auth'></SignInButton></Link></li>}  
                        { isSignedIn && <li className='hideOnMobile'><Link><UserButton></UserButton></Link></li> }
                    </div>

                    <li className='hideOnDesktop' onClick={showSideBar}><Link to='#'><svg fill={color} xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32"><path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" /></svg></Link></li>
                </ul>
            </nav>











        </div>);

}

export default Header;