import Header from "../components/Header";
import AI_Chat from "../components/AI_Chat";
import Footer from '../components/Footer';
// import Planet from '../components/Planet'
import Blob from "../components/Blob";
import transition from "../transition";


const ai_chat = () => {

    return (
        <div className="" >
        <Blob/>
            <head>
                <title>AI Chat - iCreate</title>
            </head>

            <div className="blackless">
                <Header color={"white"}></Header>

                <AI_Chat></AI_Chat>

                <Footer color={"rgb(172, 172, 172"} ></Footer>

            </div>

        </div>

    );
}

export default transition(ai_chat);