import Header from "../components/Header";
import Services_info from "../components/Services_info";
import Quote from "../components/Quote";
import Footer from "../components/Footer";
import Blob from "../components/Blob";
import transition from "../transition";


const services = () => {

    return (
        <div>
        <Blob/>
        <div className="Services_page"></div>
            <head>
                <title>Services - iCreate</title>
            </head>
            
            <div className="blackless">

            <Header color={"white"}></Header>
            <Services_info></Services_info>
            <Quote></Quote>
            <Footer color={"rgb(172, 172, 172"} ></Footer>
            </div>
        </div>
    )

}

export default transition(services);