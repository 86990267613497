import './css/Consultation_And_Form.css'
import { useState } from 'react';





const Consultation_And_Form = () => {

    const [previewConfirmation, setConfirmationPreview] = useState(false);
const handleConfirmationPreview = () => {

    setConfirmationPreview(!previewConfirmation);

}

let confirmationMessage =

    <div className='dark'>

        <div className='confirmation-message'>

            <p className='confirm-message'>Consultation Sent Successfully </p>

            <button onClick={() => handleConfirmationPreview()} className='continue-button'>
                Continue
            </button>

        </div>
    </div>

    return (

        <div className="Consultation_And_Form">

            {previewConfirmation && confirmationMessage}


            <div className='consultation_And_Form-info'>
                <div className='title'>

                    <div style={{ backgroundColor: "black" }} class="horizontal-line"></div>
                    <p data-aos-mirror="true" data-aos="slide-right" className='title-name'>Consultation</p>

                </div>
                <h1 style={{ width: "100%" }} data-aos-mirror="true" data-aos="slide-right" className='section_slogan'>Get your Consultation Now.</h1>
                {/* <p data-aos-mirror="true" data-aos="fade" className='section_content'> It is a versatile technology that can learn from vast amounts of data, making it an invaluable tool for businesses and researchers alike. From natural language processing to computer vision
                </p> */}

            </div>

            <div data-aos-once="false" data-aos-mirror="true" data-aos="fade" className='consultation-form'>

                <div className='consultation-form-inputs '>
                    <input type='date' className='consultation-form-input' placeholder='Date'></input>
                    <input className='consultation-form-input' placeholder='Hour'></input>
                    <input className='consultation-form-input' placeholder='Company'></input>
                    <input className='consultation-form-input' placeholder='Email Address'></input>
                </div>


                <textarea className='consultation-form-message' placeholder='Your Message*'></textarea>
                <button onClick={() => handleConfirmationPreview()} className='consultation-form-send-button'>Send</button>

            </div>

        </div>
    );

}

export default Consultation_And_Form;