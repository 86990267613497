import { useState } from 'react';
import './css/Main_2.css'
import { FaTelegram } from 'react-icons/fa';
import { IoIosClose } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { Fade, Slide, Roll, Flip, Hinge, JackInTheBox, Rotate, Zoom } from "react-awesome-reveal";
import { FaDeploydog } from 'react-icons/fa6';


const Main_2 = () => {


    const [showForm, setShowForm] = useState(false);
    const [previewConfirmation, setConfirmationPreview] = useState(false);



    const handleForm = () => {

        setShowForm(!showForm);

    }

    const handleConfirmationPreview = () => {

        setConfirmationPreview(!previewConfirmation);
        setShowForm(false);

    }

    let confirmationMessage =

        <div className='dark'>

            <div className='confirmation-message'>

                <p className='confirm-message'>Thank you for Reaching Us .<br></br> We Will Contact you Soon</p>

                <button onClick={() => handleConfirmationPreview()} className='continue-button'>
                    Continue
                </button>

            </div>
        </div>


    const Form = <div data-aos="fade-up" className='contact-form'>
        <IoCloseOutline size={32} style={{ cursor: "pointer", marginLeft: "auto" }} onClick={handleForm}></IoCloseOutline>

        <p className='contact-form-title'>Chat with us!</p>
        <p className='contact-form-content'>Hello, Let us know if you have any specific questions and
            we will respond shortly</p>
        <div className='contact-form-inputs'>
            <input className='contact-form-name' placeholder='Full Name*'></input>
            <input className='contact-form-email' placeholder='Email Address*'></input>
            <textarea className='contact-form-message' placeholder='How we can help*'></textarea>
        </div>
        <button onClick={() => handleConfirmationPreview()} className='contact-form-send-button'>Send</button>

    </div>



    const handleScroll = () => {
        window.scroll({
            top: window.innerHeight * 3,
            behavior: 'smooth'
        });
    };

    return (

        <div className="Main_2">


            {previewConfirmation && confirmationMessage}


            <Roll> <button onClick={handleScroll} className='scroll'>

                <h5 dir='rtl' className='scroll-text'>Scroll</h5>

                <div class="vertical-line">
                    <div class="arrow-down"></div>
                </div>

            </button>                </Roll>

            {!showForm && <button onClick={handleForm} className='contact-form-button' >
                <Roll><FaTelegram className='telegram-icon' size={54}></FaTelegram></Roll>

            </button>}




            {showForm && Form}



        </div>
    );

}


export default Main_2;