import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ClerkProvider } from '@clerk/clerk-react'


const key = process.env.VITE_CLERK_PUBLISHABLE_KEY;


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


  <React.StrictMode>
    <ClerkProvider publishableKey="pk_test_dW5jb21tb24tYm9uZWZpc2gtMTMuY2xlcmsuYWNjb3VudHMuZGV2JA">
    <Router>
      <Routes>
        <Route path='/*'  element={<App />} />
      </Routes>
    </Router>
    </ClerkProvider>
  </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
