import Header from "../components/Header";
import Main_1 from "../components/Main_1";
import Main_2 from "../components/Main_2";
import Main_3 from "../components/Main_3";
import About_Us from '../components/About_Us';
import Services from '../components/Services';
import Portfolio from '../components/Portfolio';
import Testimonials from '../components/Testimonials';
import Team from '../components/Team';
import Newsletter from '../components/Newsletter';
import Consultation from '../components/Consultation';
import Footer from '../components/Footer';
import "./css/gradients.css"
import "./css/objects.css"
import "./css/backgrounds.css"
import Blob from "../components/Blob";
import Smoke from "../components/Smoke";
import transition from "../transition";
import Animated_Blob from "../components/Animated_Blob";


const home = () => {

    return (


        <div className="black">

        <Animated_Blob/>

        {/* <div style={{position:"fixed" , zIndex:-3 , width:"100%" , height:"100vh"} }><Smoke/></div> */}


        
            <head>
                <title>iCreate</title>
            </head>
            {/* style={{backgroundImage: "url('https://i.postimg.cc/43NvJQP9/Website-Home-Screen-1.png')" ,backgroundSize:"cover", backgroundPosition:"center" , backgroundRepeat:"no-repeat"}} */}
            {/* style={{backgroundImage: "url('https://i.postimg.cc/RFmB6q3K/Website-Home-Screen-2.png')" ,backgroundSize:"cover", backgroundPosition:"center" , backgroundRepeat:"no-repeat"}} */}
            <div className="">

                
         
              
                <Header color={"white"}></Header>
                <Main_1></Main_1>
                <Main_2></Main_2>
            </div>



            <div className="">

                <Main_3></Main_3>
                

                <div className="shadow" style={{ paddingTop: "40px", borderTop: "solid 1px white" }}><About_Us color={"white"}>

               
                </About_Us></div>
            </div>

            <div className="shadow" style={{  paddingTop: "40px", borderTop: "solid 1px white" }}>
                    <Services color={"white"}></Services>
            </div>

            {/* <div style={{backgroundImage: "url('https://i.postimg.cc/g08p230G/Website-Home-Screen-3.png')" ,backgroundSize:"cover", backgroundPosition:"center" , backgroundRepeat:"no-repeat"}}> */}

            <div className="shadow" style={{ paddingTop: "40px", borderTop: "solid 1px white" }} >
                <Portfolio color={"white"}></Portfolio>

            </div>

            {/* <div  style={{backgroundImage: "url('https://i.postimg.cc/rm281QHd/Website-Home-Screen-4.png')" ,backgroundSize:"cover", backgroundPosition:"center" , backgroundRepeat:"no-repeat"}}> */}

            {/* <div className="shadow"  style={{ paddingTop: "100px", borderTop: "solid 1px white" }} className="">

                <Testimonials></Testimonials>
            </div> */}
            <div className="team_gradient">



            </div>


            <div className="">
                {/* <div style={{ borderTop: "solid 1px white" }}><Team color={"white"}></Team></div> */}
                <div style={{ borderTop: "solid 1px white" }}><Newsletter></Newsletter></div>

                <div style={{  borderTop: "solid 1px white" }}>
                    <Consultation></Consultation>
                    <Footer color={"rgb(172, 172, 172"} ></Footer>
                </div>

            </div>

        </div>

    );
}

export default transition(home);