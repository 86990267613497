import './css/Main_1.css'
import { FaTwitter, FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';
import { GrTelegram } from 'react-icons/gr';
import { Fade , Slide , Roll , Flip , Hinge , JackInTheBox , Rotate , Zoom} from "react-awesome-reveal";
import { Link } from 'react-router-dom';

const Main_1 = () => {

    return (

        <div className="Main_1">


            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>

            
            <div className='social-links'>
                {/* <a href="#" class="fa fa-2x fa-twitter"></a>
                <a href="#" class="fa fa-2x fa-facebook"></a>
                <a href="#" class="fa fa-2x fa-instagram"></a> */}
               <a href="https://twitter.com/iCreate_Egypt" class="fa"> <Zoom><FaTwitter  className='social-icon' />  </Zoom></a>
                <a href="https://www.facebook.com/icreate.visualart" class="fa"><Zoom><FaFacebook  className='social-icon' /></Zoom></a>
                <a href="https://www.instagram.com/icreate.egypt/" class="fa"><Zoom><FaInstagram  className='social-icon' /></Zoom></a>
                <a href="https://www.tiktok.com/@icreate.egypt?_t=8kfSN8owwOz&_r=1" class="fa"><Zoom><FaTiktok className='social-icon' /></Zoom></a>
            </div>
           

            <div className='marketing'>

                <Slide> <h1 className='marketing-title glow'>WHAT DOES SUCCESS <br></br> LOOK LIKE?</h1></Slide>

                {/* <p className='message'>
                <Fade>
                Are you prepared to advance your company's success and professionalism? iCreate is the only place to look! By using our tactics to get the greatest results, our team of specialists is committed to helping you succeed and discover new opportunities. As your target audience's needs and the state of digital marketing change, you must constantly evaluate and modify your strategy.Grow your company alongside us
                </Fade>
                </p> */}
               

                <Fade><Link to='/contact' ><button className='contact-us-button'>Contact Us</button></Link></Fade>
                
            </div>


        </div>
    );

}


export default Main_1;