import Header from "../components/Header";
import Portfolio from "../components/Portfolio";
import Footer from "../components/Footer";
import projects from '../components/projects-test.json'
import Blob from "../components/Blob";
import transition from "../transition";


const portfolio = () => {

    return (
        <div className="" >
        <Blob/>
            <head>
                <title>Portfolio - iCreate</title>
            </head>
            <div className="blackless">
           <Header color={"white"}></Header>
            <Portfolio color={"white"}  complete={false}></Portfolio>
            <Footer color={"rgb(172, 172, 172"} ></Footer>
            </div>
        </div>
    )

}

export default transition(portfolio);