import './css/About_Us.css'
import { Fade , Slide , Roll , Flip , Hinge , JackInTheBox , Rotate , Zoom} from "react-awesome-reveal";
import { Link } from 'react-router-dom';


const About_Us = ({ backgroundColor, color, complete = true }) => {


    return (

        <div style={{ backgroundColor: backgroundColor, color: color }} className="About_Us">


            <div style={{ color: color }} className='title'>

                <div style={{ backgroundColor: color }} class="horizontal-line"></div>
                <p data-aos-mirror="true" data-aos="slide-right"  className='title-name'>About Us</p>

            </div>
            <h1 data-aos-mirror="true" data-aos="slide-right" className='section_slogan'>We are Experts in  our field.</h1>
           <p data-aos-mirror="true" data-aos="fade" className='section_content'> 
            We are iCreate, a full -service digital marketing agency, founded in 2020, based in Madinaty, New Cairo.
We are artists, designers, engineers, thinkers and learners, builders and dreamers. We are hell bent on creating the best digital products in the world. Our mission is to reimagine how people interact with brands. 
            </p>

            {complete && <Link  className='section_learn_more' style={{color:"white"}} to='/about'>Learn More</Link>}
            {complete && <Link  className='section_learn_more_480px' style={{color:"white"}} to='/about'>Learn More</Link>}

        </div>
    );

}

export default About_Us;