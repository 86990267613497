import './css/Services.css'
import { LiaPencilAltSolid } from "react-icons/lia";
import { MdDeveloperMode } from "react-icons/md";
import { TbBrandDatabricks } from "react-icons/tb";
import { BsChatSquareText } from "react-icons/bs";
import ReactCardFlip from 'react-card-flip';
import { useState } from "react";
import { Link } from 'react-router-dom';

const Services = ({ backgroundColor, color, complete = true }) => {

    const [isFlippedArray, setIsFlippedArray] = useState([false, false, false, false]); // Initial state for 3 cards

    const flipCard = (index) => {
        const newFlippedArray = [...isFlippedArray];
        newFlippedArray[index] = !newFlippedArray[index];
        setIsFlippedArray(newFlippedArray);
    }

    return (

        <div style={{ backgroundColor: backgroundColor, color: color }} className="Services">


            <div style={{ color: color }} className='title'>

                <div style={{ backgroundColor: color }} class="horizontal-line"></div>
                <p data-aos-mirror="true" data-aos="slide-right" className='title-name'>Services</p>

            </div>
            <h1 data-aos-mirror="true" data-aos="slide-right" className='section_slogan'>We do everything.</h1>

            <p data-aos-mirror="true" data-aos="fade" className='section_content'>

                We operate with start-ups to large operation companies by providing them with full digital marketing services.
            </p>

            {complete && <Link  className='section_learn_more' style={{color:"white"}} to='/services'>Learn More</Link>}

            {complete && <button className='section_learn_more_480px'><Link style={{color:"white"}} to='/services'>Learn More</Link></button>}



            {complete && <div className='services-cards'>


                <div data-aos-mirror="true" data-aos="fade-up" >

                    <ReactCardFlip flipDirection="horizontal" isFlipped={isFlippedArray[0]}>

                        <div onClick={() => flipCard(0)} className='service-card'>


                            <img src='https://i.postimg.cc/FHh925BZ/uxui.png' className='service-card-logo'></img>

                            <h4 className='service-card-title'>UI/UX Design</h4>

                        </div>

                        <div style={{backgroundColor:"rgba(21, 21, 22, 0.5)"}} onClick={() => flipCard(0)} className='service-card'>

                            <p className='service-card-content'>
                                It is a versatile technology that can learn from vast amounts of data, making it an invaluable tool for businesses and researchers alike. From natural language processing to computer vision
                            </p>
                        </div>

                    </ReactCardFlip>
                </div>



                <div data-aos-mirror="true" data-aos="fade-up" >

                    <ReactCardFlip flipDirection="horizontal" isFlipped={isFlippedArray[1]}>

                        <div onClick={() => flipCard(1)} className='service-card'>


                            <img src='https://i.postimg.cc/dt2sHt43/development.png' className='service-card-logo'></img>

                            <h4 className='service-card-title'>Development</h4>


                        </div>

                        <div style={{backgroundColor:"rgba(21, 21, 22, 0.5)"}} onClick={() => flipCard(1)} className='service-card'>

                            <p className='service-card-content'>
                                We are committed to provide complete websites for frontend and backend solutions to our clients and E-Commerce website services so that your business runs smoothly and uninterrupted while we take care of your domains, hosting and emails.
                            </p>
                        </div>

                    </ReactCardFlip>
                </div>




                <div data-aos-mirror="true" data-aos="fade-up" >

                    <ReactCardFlip flipDirection="horizontal" isFlipped={isFlippedArray[2]}>

                        <div onClick={() => flipCard(2)} className='service-card'>


                            <img src='https://i.postimg.cc/fL1zFVGt/branding.png' className='service-card-logo'></img>

                            <h4 className='service-card-title'>Branding</h4>



                        </div>

                        <div style={{backgroundColor:"rgba(21, 21, 22, 0.5)"}} onClick={() => flipCard(2)} className='service-card'>

                            <p className='service-card-content'>
                                We understand the more you speak about yourself, the more your customers will know about you. We provide the branding services through Logo, Corporate Identity, Website, Social Media Marketing, Brochures, Flyers, Vehicle Design.
                            </p>
                        </div>

                    </ReactCardFlip>
                </div>


                <div data-aos-mirror="true" data-aos="fade-up" >

                    <ReactCardFlip flipDirection="horizontal" isFlipped={isFlippedArray[3]}>

                        <div onClick={() => flipCard(3)} className='service-card'>


                            <img src='https://i.postimg.cc/mZF29S9Q/consulting.png' className='service-card-logo'></img>

                            <h4 className='service-card-title'>Consulting</h4>




                        </div>

                        <div style={{backgroundColor:"rgba(21, 21, 22, 0.5)"}} onClick={() => flipCard(3)} className='service-card'>

                            <p className='service-card-content'>
                                We offer comprehensive consulting services to guide clients through the dynamic e-marketing landscape. Our feature concept revolves around being a trusted advisor, providing customized digital marketing strategies, performance analysis, target audience segmentation, and insights into industry trends and innovation.
                            </p>
                        </div>

                    </ReactCardFlip>
                </div>



            </div>}
        </div>

    );

}

export default Services;