import './css/Footer.css'
import { Link } from 'react-router-dom';


const Footer = ({ backgroundColor, color, complete = true })=>{

    return (

        <div style={{ backgroundColor: backgroundColor, color: color }} className='Footer'>
          
           <p>All rights reserved 2024</p>

           <div className='footer-links'>
            <Link style={{color: color }} className='footer-link' to='/home'>Home</Link>
            <span className='footer-space'>|</span>
            <Link style={{color: color }} className='footer-link' to='/careers'>Careers</Link>
            <span className='footer-space'>|</span>
            <Link style={{color: color }} className='footer-link' to='/services'>Our Services</Link>
            <span className='footer-space'>|</span>
            <Link style={{color: color }} className='footer-link' to='/consultation'>Get a Consultation</Link>
           </div>

        </div>
    );

}

export default Footer;