import './css/Story.css'

const Story = () => {


    return (

        <div className='Story'>

            <h2 data-aos-mirror="true" data-aos="fade" className='story-title'>Our Story</h2>

            <div className='story-content'>

                <div className='left-content'>
                    <p data-aos-once="false"  data-aos-mirror="true" data-aos="slide-down">Our mission is to reimagine how poeple interacts with brands, We aim to be stratigic
                        people in emergencies.</p>
                    <p data-aos-once="false"  data-aos-mirror="true" data-aos="slide-down">we have made all our efforts to achieve the maximum that can be reached,
                        so we have become the best partner that can be chose in the labor market.</p>
                    <p data-aos-once="false"  data-aos-mirror="true" data-aos="slide-down">
                        There are no ceiling for our ambition, we always strive for the best by seeking education,
                        development and apadtation for situations.
                    </p>

                  

                        <p data-aos-once="false"  data-aos-mirror="true" data-aos="slide-down" className='combine'>Our goal is to build the future with all our energy: therefore, we are present in the labor market. </p>

                        <p data-aos-once="false"  data-aos-mirror="true" data-aos="slide-down" className='combine'>We`re praving the way to become the most creative agency you can deal with.</p>

                        <p data-aos-once="false"  data-aos-mirror="true" data-aos="slide-down" className='combine'>We are working on making branding a priority, increasing brand awareness, designing luxurious touch platforms, implanting digital marketing solutions,
                            and creating innovative content and digital strategy from efficiency to innovation.</p>

                </div>





                <div className='story-grid'>

                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                </div>

                <div className='right-content'>

                    <p data-aos-once="false"  data-aos-mirror="true" data-aos="slide-down" className='second' style={{ marginTop: "18vh" }}>Our goal is to build the future with all our energy: therefore, we are present in the labor market. </p>

                    <p data-aos-once="false"  data-aos-mirror="true" data-aos="slide-down" className='fourth' style={{ marginTop: "20vh" }}>We`re praving the way to become the most creative agency you can deal with.</p>

                    <p data-aos-once="false"  data-aos-mirror="true" data-aos="slide-down" className='sixth' style={{ marginTop: "13vh" }}>We are working on making branding a priority, increasing brand awareness, designing luxurious touch platforms, implanting digital marketing solutions,
                        and creating innovative content and digital strategy from efficiency to innovation.</p>

                </div>

            </div>
        </div>
    );

}

export default Story;