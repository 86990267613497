import Header from "../components/Header";
import About_Us from '../components/About_Us';
import Footer from '../components/Footer';
import Story from "../components/Story";
import How_we_work from "../components/How_we_work";
import Planet from '../components/Planet'
import Space from "../components/Space";
import Blob from "../components/Blob";
import transition from "../transition";

const about = () => {
// style={{background: "linear-gradient(90deg, rgba(13,10,23,1) 32%, rgba(190,10,177,1) 100%)"}}
    return (
        <div className="about_page"  >
        <Blob/>
        <head>
        <title>About - iCreate</title>
        </head>
      

        <div className="blackless">
            <Header color={"white"}></Header>

            <div style={{paddingTop:"100px"}}>
            <About_Us  color={"white"} complete={false}></About_Us>
            </div>
            <Story></Story>
            <How_we_work></How_we_work>

            <Footer color={"rgb(172, 172, 172"} ></Footer>

        
        </div>
        
        
        </div>

    );
}

export default transition(about);