import React, { useEffect } from 'react';
import { spline } from 'https://cdn.skypack.dev/@georgedoescode/spline@1.0.1';
import SimplexNoise from 'https://cdn.skypack.dev/simplex-noise@2.4.0';
import './css/Animated_Blob.css'


const Animated_Blob = () => {
  
  
  useEffect(() => {
    const path = document.querySelector('path');

    let hueNoiseOffset = 0;
    let noiseStep = 0.0025;
    const simplex = new SimplexNoise();
    const points = createPoints();

    function animate() {
      path.setAttribute('d', spline(points, 1, true));

      for (let i = 0; i < points.length; i++) {
        const point = points[i];
        const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
        const nY = noise(point.noiseOffsetY, point.noiseOffsetY);

        const x = map(nX, -1, 1, point.originX - 20, point.originX + 20);
        const y = map(nY, -1, 1, point.originY - 20, point.originY + 20);

        point.x = x;
        point.y = y;

        point.noiseOffsetX += noiseStep;
        point.noiseOffsetY += noiseStep;
      }

      const hueNoise = noise(hueNoiseOffset, hueNoiseOffset);
      const hue = map(hueNoise, -1, 1, 0, 360);


      hueNoiseOffset += noiseStep / 6;

      requestAnimationFrame(animate);
    }

    function map(n, start1, end1, start2, end2) {
      return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
    }

    function noise(x, y) {
      return simplex.noise2D(x, y);
    }

    function createPoints() {
      const points = [];
      const numPoints = 6;
      const angleStep = (Math.PI * 2) / numPoints;
      const rad = 75;

      for (let i = 1; i <= numPoints; i++) {
        const theta = i * angleStep;
        const x = 100 + Math.cos(theta) * rad;
        const y = 100 + Math.sin(theta) * rad;

        points.push({
          x: x,
          y: y,
          originX: x,
          originY: y,
          noiseOffsetX: Math.random() * 1000,
          noiseOffsetY: Math.random() * 1000,
        });
      }

      return points;
    }

    document.querySelector('path').addEventListener('mouseover', () => {
      noiseStep = 0.01;
    });

    document.querySelector('path').addEventListener('mouseleave', () => {
      noiseStep = 0.002;
    });

    animate();

    // Clean up event listeners when unmounting
    return () => {
      document.querySelector('path').removeEventListener('mouseover', () => {
        noiseStep = 0.01;
      });

      document.querySelector('path').removeEventListener('mouseleave', () => {
        noiseStep = 0.002;
      });
    };
  }, []);


  return (
    <div>


      <svg className='Animated_Blob' viewBox="0 0 200 200" width="100%">
        <defs>
          <linearGradient id="gradient">
            <stop offset="0%" style={{stopColor: "rgb(233, 100, 67)"}}></stop>
            <stop offset="100%" style={{stopColor: "rgb(144, 78, 149)"}}></stop>
          </linearGradient>
        </defs>
        <path d="" fill="url('#gradient')"></path>
      </svg>

     
    </div>

  )
}

export default Animated_Blob;