import Header from "../components/Header";
import Text_to_Image from "../components/Text_to_Image";
import Footer from '../components/Footer';
import Blob from "../components/Blob";
import transition from "../transition";



const text_to_image = () => {

  

    return (
        <div className="">
            <Blob/>
            <head>
                <title>Text to Image - iCreate</title>
            </head>
            <div className="blackless">

            <Header color={"white"}></Header>
           
            <Text_to_Image></Text_to_Image>

            <Footer color={"rgb(172, 172, 172)"} ></Footer>
            </div>


        </div>

    );
}

export default transition(text_to_image);