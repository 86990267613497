import { useState } from 'react';
import './css/Job.css'
import jobs from './jobs-test.json'
import { FaLocationDot } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import { useParams } from 'react-router-dom';



const Job = () => {

    let { id } = useParams();

    id -= 1;

    const [selectedJob, setJob] = useState(0);
    const [previewApplyForm, setPreview] = useState(false);
    const [previewConfirmation, setConfirmationPreview] = useState(false);

    const [category, setCategory] = useState('All');


    const handlePreview = (id) => {

        setPreview(!previewApplyForm);
        setJob(id - 1);

    }

    const handleConfirmationPreview = () => {

        setPreview(false);
        setConfirmationPreview(!previewConfirmation);

    }

    const handleCatg = (catg) => {

        setCategory(catg);
    }


    let jobForm =

        <div data-aos-offset="0" data-aos-once="false"  data-aos="zoom-in-up"   className='dark'>

            <div className='jobForm'>

                <IoCloseOutline size={28} style={{ cursor: "pointer", marginLeft: "auto" }} onClick={() => handlePreview(1)}></IoCloseOutline>

                <p className='input-title'>Email Address</p>
                <input className='jobForm-input' placeholder='Enter Email Adderess'></input>

                <p className='input-title'>Mobile Phone</p>
                <input className='jobForm-input' placeholder='Enter Mobile Number '></input>

                <p className='input-title'>Resume</p>
                <input type="file" accept=".pdf" className='jobForm-input' placeholder='Insert Resume '></input>

                <button onClick={() => handleConfirmationPreview()} className='jobForm-apply-button'>
                    Apply
                </button>

            </div>
        </div>



    let confirmationMessage =

        <div data-aos-offset="0" data-aos-once="false"  data-aos="zoom-in-up"   className='dark'>

            <div className='confirmation-message'>

                <p className='confirm-message'>You have submitted your Application successfully!</p>

                <button onClick={() => handleConfirmationPreview()} className='continue-button'>
                    Continue
                </button>

            </div>
        </div>
    return (

        <div className="Job">

            {previewApplyForm && jobForm}

            {previewConfirmation && confirmationMessage}

            {/* <div className='title'>

                <div style={{ backgroundColor: "black" }} class="horizontal-line"></div>
                <p data-aos-mirror="true" data-aos="slide-right"  className='title-name'>Careers</p>


            </div> */}
            <h1 data-aos-mirror="true" data-aos="slide-right" className='section_slogan'>Job Reqiurements</h1>



            <div className='job'>

                <p data-aos-mirror="true" data-aos="slide-right" className='job_title'>
                    {jobs[id].title}
                </p>

                <p data-aos-mirror="true" data-aos="slide-right" className='job_description'>
                    {jobs[id].description}
                </p>

                <div data-aos-mirror="true" data-aos="slide-right" className='job_details'>
                    <div className='job_location'>
                        <FaLocationDot></FaLocationDot>
                        <p>{jobs[id].location}</p>
                    </div>
                    <div className='job_type'>
                        <FaClock></FaClock>
                        <p>{jobs[id].type}</p>
                    </div>
                </div>


                <button data-aos-once="false" data-aos-mirror="true" data-aos="slide-right" onClick={() => handlePreview(jobs[id].Id)} className='job_apply_button'>
                    Apply
                </button>


                <div className='job_definition'>

                    <p  data-aos-once="false" data-aos-mirror="true" data-aos="fade-right" className='job_definition_title'>You will:</p>
                    <div data-aos-once="false" data-aos-mirror="true" data-aos="fade-right" className='definition_points'>
                        <p>-  We look for new member on our team.</p>
                        <p>-  We look for new member on our team.</p>
                        <p>-  We look for new member on our team.</p>
                        <p>-  We look for new member on our team.</p>
                    </div>
                    <p data-aos-once="false" data-aos-mirror="true" data-aos="fade-right" style={{ marginTop: "30px" }} className='job_definition_title'>Responsabilities:</p>
                    <div data-aos-once="false" data-aos-mirror="true" data-aos="fade-right" className='definition_points'>
                        <p>- We look for new member on our team.</p>
                        <p>- We look for new member on our team.</p>
                        <p>- We look for new member on our team.</p>
                        <p>- We look for new member on our team.</p>
                    </div>


                </div>
            </div>


        </div>


    );

}

export default Job;