import './css/Quote.css'
import { useState } from 'react';


const Quote = () => {

    const [previewConfirmation, setConfirmationPreview] = useState(false);
    const handleConfirmationPreview = () => {

        setConfirmationPreview(!previewConfirmation);

    }

    let confirmationMessage =

        <div className='dark'>

            <div className='confirmation-message'>

                <p className='confirm-message'>Quote Sent Successfully </p>

                <button onClick={() => handleConfirmationPreview()} className='continue-button'>
                    Continue
                </button>

            </div>
        </div>


    return (

        <div className="Quote">

            {previewConfirmation && confirmationMessage}



            <div className='quote-info'>
                <div className='title'>

                    <div style={{ backgroundColor: "black" }} class="horizontal-line"></div>
                    <p data-aos-mirror="true" data-aos="slide-right" className='title-name'>Quotation</p>

                </div>
                <h1 style={{ width: "100%" }} data-aos-mirror="true" data-aos="slide-right" className='section_slogan'>Get a Quote Now.</h1>
                {/* <p data-aos-mirror="true" data-aos="fade" className='section_content'> It is a versatile technology that can learn from vast amounts of data, making it an invaluable tool for businesses and researchers alike. From natural language processing to computer vision
                </p> */}

            </div>

            <div data-aos-once="false" data-aos-mirror="true" data-aos="fade" className='quote-form'>

                <div className='quote-form-inputs '>
                    <input className='quote-form-input' placeholder='Name'></input>
                    <input className='quote-form-input' placeholder='Email Address'></input>
                    <input className='quote-form-input' placeholder='Subject'></input>
                    <input className='quote-form-input' placeholder='Phone Number'></input>
                </div>


                <textarea className='quote-form-message' placeholder='Your Message*'></textarea>
                <button  onClick={() => handleConfirmationPreview()} className='quote-form-send-button'>Send</button>

            </div>

        </div>
    );

}

export default Quote;