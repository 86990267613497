import Header from "../components/Header";
import Contact from "../components/Contact";
import Footer from '../components/Footer';
import Blob from "../components/Blob";
import transition from "../transition";

const contact = () => {

    return (
        <div className="" >

            <Blob/>
            <head>
                <title>Contact - iCreate</title>
            </head>

            <div className="blackless">
                <Header color={"white"}></Header>

                <Contact></Contact>

                <Footer color={"rgb(172, 172, 172"} ></Footer>

            </div>



        </div>

    );
}

export default transition(contact);