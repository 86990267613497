import './css/How_we_work.css'

const How_we_work = () => {


    return (

        <div className='How_we_work'>

            <h1 data-aos-mirror="true" data-aos="slide-right" className='How_we_work_title'>How we work.</h1>

            <div className='How_we_work_content'>

                <div data-aos-once="false"  data-aos-mirror="true" data-aos="fade" className='How_we_work_item'>
                    <p>iCreate is not just your typical full service digital marketing agency, we drive business
                     outcomes for our clients with a method called Revenue - Driven Optimization (RDO).</p>

                </div>
               
                <div data-aos-once="false"  data-aos-mirror="true" data-aos="fade" className='How_we_work_item'>
                    <p>We communicate your uniqueness your voice and your values in a way that is visually appealing, and uncover your goals, 
                    strategy, and mission to develop a strategy for success deliverables.</p>

                </div>

                <div data-aos-once="false"  data-aos-mirror="true" data-aos="fade" className='How_we_work_item'>
                    <p>We solve tomorrow's business challenges in thoughtful, elegant ways.</p>

                </div>
                
                <div data-aos-once="false"  data-aos-mirror="true" data-aos="fade" className='How_we_work_item'>
                    <p>Milestones are very important factor in our work, as we work in two - week sprints,
                     so you`re always up to speed with status of deliverables.</p>

                </div>
               
                <div data-aos-once="false"  data-aos-mirror="true" data-aos="fade" className='How_we_work_item'>
                    <p>We never compromise on quality!</p>

                </div>

                <div data-aos-once="false"  data-aos-mirror="true" data-aos="fade" className='How_we_work_item'>
                    <p>Our data-first approach combines platform automation with
                     captivating creative work to optimize toward profitable revenue, not just leads or sales.</p>

                </div>
                
                <div data-aos-once="false"  data-aos-mirror="true" data-aos="fade" className='How_we_work_item'>
                    <p>We belive the best solutions lay at the intersection of intelligent design and strong engineering.</p>

                </div>
               
                <div data-aos-once="false"  data-aos-mirror="true" data-aos="fade" className='How_we_work_item'>
                    <p>Being strategic leaders in emergent technologies , and innovators in user experiences,
                     as we arm businesses for the digital revolution.</p>

                </div>

                <div data-aos-once="false"  data-aos-mirror="true" data-aos="fade" className='How_we_work_item'>
                    <p>
                    By working smarterand using software that saves both time and money,
                     we have managed to increase efficiency in competency to take your business to another level.
                    </p>

                </div>


            </div>
        </div>
    );

}

export default How_we_work;