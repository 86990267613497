import { useState } from "react";
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react'
import "./css/AI_Chat.css"


const AI_Chat = () => {

    const [typing, setTyping] = useState(false);
    const [messages, setMessages] = useState([]);

    const handleSend = async (messgae) => {

        const newMessage = {
            message: messgae,
            sender: "user",
            direction: "outgoing"
        }

        const newMessages = [...messages, newMessage];

        setMessages(newMessages);

        setTyping(true);

        const response = await fetch(`https://genai-mccq.onrender.com/api/ai_chat?message=${newMessage.message}`);

        const data = await response.text();

        const newAIMessage = {
            message: data,
            sender: "AI",
            direction: "left"
        }

        setMessages([...newMessages,  newAIMessage]);
        
       
        setTyping(false);

    }

    return (

        <div className='AI_Chat'>

            <h1 data-aos-mirror="true" data-aos="fade" className="ai_chat_title">AI Chat</h1>

            <MainContainer data-aos-mirror="true" data-aos="fade" style={{opacity:"0.7" , height:"50vh" , width:"100%", borderRadius:"10px"}}>
                <ChatContainer  style={{width:"100%" }} >
                    <MessageList scrollBehavior="smooth" style={{width:"100%" ,padding:"10px", background:"linear-gradient(to right,   rgb(61, 59, 59) , rgb(25, 28, 38))"}}
                        typingIndicator={typing && <TypingIndicator style={{background:"linear-gradient(to right,   rgb(61, 59, 59) , rgb(25, 28, 38))"}} content="Loading"></TypingIndicator>}
                    >
                        {messages.map((message, i) => {

                            return <Message style={{marginTop:"30px"}} key={i} model={message}></Message>
                        })}
                    </MessageList>
                    <MessageInput placeholder="Type message here" onSend={handleSend}></MessageInput>
                </ChatContainer>
            </MainContainer>


        </div>

    )

}

export default AI_Chat;