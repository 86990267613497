import { Canvas, useFrame, useThree, extend , useLoader } from '@react-three/fiber'
import { useRef } from 'react'
import { TextureLoader , Mesh, MeshStandardMaterial, CubeTextureLoader, WebGLCubeRenderTarget, RGBFormat, LinearMipMapLinearFilter, CubeCamera, PointLight } from 'three';
import { OrbitControls } from 'three/examples/jsm/Addons.js';
extend({ OrbitControls })


const CameraControls = () => {

    const { camera, gl: { domElement } } = useThree()

    const controls = useRef();


    useFrame(() => {
        controls.current.update();

    })

    return (<orbitControls
        ref={controls}
        args={[camera, domElement]}
        autoRotate={true}
        autoRotateSpeed={2}
        enableZoom={false}
        enablePan={false}
        enableDamping={true}

    />)
}



const TextureBG = () => {

    const { scene } = useThree();

    const loader = new CubeTextureLoader();
    const texture = loader.load([   
        "https://images.prodia.xyz/24bc981c-97dc-4124-b3da-8d911702b634.png",
        "https://images.prodia.xyz/24bc981c-97dc-4124-b3da-8d911702b634.png",
        "https://images.prodia.xyz/24bc981c-97dc-4124-b3da-8d911702b634.png",
        "https://images.prodia.xyz/24bc981c-97dc-4124-b3da-8d911702b634.png",
        "https://images.prodia.xyz/24bc981c-97dc-4124-b3da-8d911702b634.png",
        "https://images.prodia.xyz/24bc981c-97dc-4124-b3da-8d911702b634.png",
    ])
    scene.background = texture;
    return null;
}



const Sphere = () => {
    const meshRef = useRef();
    const colorMap = useLoader(TextureLoader, '8k_sun.jpg')
    // const { scene, gl } = useThree();
    // const cubeRenderTarget = new WebGLCubeRenderTarget(512, {
    //     format: RGBFormat,
    //     generateMipmaps: true,
    //     minFilter: LinearMipMapLinearFilter

    // });

    // const cubeCamera = new CubeCamera(1, 1000, cubeRenderTarget);

    // cubeCamera.position.set(0, 0, 0);
    // scene.add(cubeCamera)

    // useFrame(() => {
    //     if (meshRef.current) {
    //         meshRef.current.rotation.x += 0.01;
    //         meshRef.current.rotation.y += 0.01;
    //         cubeCamera.update(gl, scene)

    //     }
    // });


    // const handleMouseMove = (event) => {
    //     if (meshRef.current) {
    //         // Update the position of the point light based on mouse movement
    //         const mouseX = event.clientX - window.innerWidth / 2;
    //         const mouseY = event.clientY - window.innerHeight / 2;
    //         meshRef.current.rotation.color='red'
    //     }
    // };

    // // Add event listener for mouse move
    // document.addEventListener('mousemove', handleMouseMove);



    return (
        <mesh
            ref={meshRef}
            position={[0, 0, 0]}
           
        >
            <sphereGeometry args={[2.5, 64, 64]} />
            <meshStandardMaterial
                // metalness={1} // Adjust the metalness to control the metal appearance
                roughness={0.5} // Adjust the roughness to control the roughness of the metal surface
                map={colorMap}     
            />
        </mesh>
    )
}

const PointLightAnimation = () => {
    const pointLightRef = useRef();

    const handleMouseMove = (event) => {
        if (pointLightRef.current) {
            // Update the position of the point light based on mouse movement
            const mouseX = event.clientX - window.innerWidth / 2;
            const mouseY = event.clientY - window.innerHeight / 2;
            pointLightRef.current.position.x = mouseX;
            pointLightRef.current.position.y = -mouseY;
        }
    };

    // Add event listener for mouse move
    document.addEventListener('mousemove', handleMouseMove);

   

    return (
        <ambientLight ref={pointLightRef} position={[0, 15, 0]} intensity={1} />
    );
};
export default function Planet() {
    return (
            <Canvas>
                <PointLightAnimation />

                <Sphere />
                {/* <TextureBG /> */}
                <CameraControls/> 
            </Canvas>
    )
}