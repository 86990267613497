import './css/Newsletter.css'
import { useState } from 'react';


const Newsletter = () => {


    const [previewConfirmation, setConfirmationPreview] = useState(false);
    const handleConfirmationPreview = () => {

        setConfirmationPreview(!previewConfirmation);

    }

    let confirmationMessage =

        <div className='dark'>

            <div className='confirmation-message'>

                <p className='confirm-message'>Thank You For Your Suscribtion </p>

                <button onClick={() => handleConfirmationPreview()} className='continue-button'>
                    Continue
                </button>

            </div>
        </div>


    return (

        <div className="Newsletter">

{previewConfirmation && confirmationMessage}

            {/* <div className='title'>

                <div style={{backgroundColor:"white"}} class="horizontal-line"></div>
                <p data-aos-mirror="true" data-aos="slide-right" className='title-name'>Newsletter</p>

            </div> */}
            <h1 style={{width:"100%"}} data-aos-mirror="true" data-aos="slide-right" className='section_slogan'>Let`s talk and keep you updated.</h1>

            <p data-aos-mirror="true" data-aos="fade" className='section_content'> 
            Suscribe to our newsletter and never miss our latest news.
            </p>

        
            <div className='newsletter-form'>
                <input className='newsletter-form-input'></input>
                <button onClick={() => handleConfirmationPreview()} className='newsletter-suscribe-button'>Suscribe</button>
            </div>

            <button onClick={() => handleConfirmationPreview()} className='newsletter-suscribe-button-480px'>Suscribe</button>

           
        </div>

    );

}

export default Newsletter;