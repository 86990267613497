import { useState } from 'react';
import './css/Portfolio.css'
import projects from './projects-test.json'
import ReactCardFlip from 'react-card-flip';
import { IoCloseOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';

const Portfolio = ({ backgroundColor, color, complete = true }) => {


    const [selectedProject, setProject] = useState(0);
    const [previewProject, setPreview] = useState(false);
    const [category, setCategory] = useState('All');


    const handlePreview = (id) => {

        setPreview(!previewProject);
        setProject(id - 1);

    }

    const handleCatg = (catg) => {

        setCategory(catg);
    }


    let Project =

        <div data-aos-offset="0" data-aos-once="false"  data-aos="zoom-in-up"    className='dark'>
        
            <div className='Project'>
            <IoCloseOutline size={28} style={{ cursor: "pointer", marginLeft: "auto" }} onClick={() => handlePreview(1)}></IoCloseOutline>
                <img onClick={() => handlePreview(1)} className='project-image' src={projects[selectedProject].image}></img>
                <div className='project-info'>
                    <p style={{fontFamily:"parag", fontWeight: "bold" }}>About the Project</p>
                    <p className='project-about'>{projects[selectedProject].about}</p>
                    <p style={{fontFamily:"parag", fontWeight: "bold", marginTop: "3%" }}>Project Keywords</p>

                    <div className='project-keywords'>
                        {projects[selectedProject].keywords.map(keyword => (

                            <p>- {keyword}</p>
                        ))}
                    </div>

                </div>

                <h1 className='project-title'>{projects[selectedProject].title}</h1>

            </div>
        </div>
    return (

        <div style={{ backgroundColor: backgroundColor, color: color }} className="Portfolio">

            {previewProject && Project}


            <div style={{ color: color }} className='title'>

                <div style={{ backgroundColor: color }} class="horizontal-line"></div>
                <p data-aos-mirror="true" data-aos="slide-right" className='title-name'>Portfolio</p>


            </div>
            <h1 data-aos-mirror="true" data-aos="slide-right" className='section_slogan'>Our Projects.</h1>

            {complete && <p data-aos-mirror="true" data-aos="fade" className='section_content'> iCreate is more than just a website - it's a sanctuary for the imagination, where creativity flourishes and ideas take flight. Unleash your artistic potential and create a masterpiece today!
            </p>}
            
            {complete &&  <Link className='section_learn_more' style={{color:"white"}} to='/portfolio'>Learn More</Link>}


            {complete && <button data-aos-mirror="true" data-aos="fade" className='section_learn_more_480px'><a style={{color:"white"}} href='/portfolio'>Learn More</a></button>}


            <div  data-aos-mirror="true" data-aos="zoom-in-up" className='catgs_bar'>

                <button onClick={() => handleCatg("All")} style={{ color: color }} className='catg'>All</button>
                <span className='catgs-bar-dash'>\</span>
                <button onClick={() => handleCatg("Web and App Development")} style={{ color: color }} className='catg'>Web and App Development</button>
                <span className='catgs-bar-dash'>\</span>
                <button onClick={() => handleCatg("Branding & Identity")} style={{ color: color }} className='catg'>Branding & Identity</button>
                <span className='catgs-bar-dash'>\</span>
                <button onClick={() => handleCatg("Software Development")} style={{ color: color }} className='catg'>Software Development</button>
                <span className='catgs-bar-dash'>\</span>
                <button onClick={() => handleCatg("Marketing")} style={{ color: color }} className='catg'>Marketing</button>

            </div>
            <div className='portfolio-projects'>

                <div className='portfolio-projects-column1'>

                    {projects.filter(item => {

                        if (item.Id % 2 != 0) {
                            if (item.category === category || category === 'All') return true;
                        }
                        return false;


                    }).map((project) => (


                        <img data-aos-once="true" data-aos-mirror="true" data-aos="fade-right" onClick={() => handlePreview(project.Id)} className='portfolio-projects-item' src={project.image}></img>

                    ))}
                </div>


                <div className='portfolio-projects-column2'>

                    {projects.filter(item => {

                        if (item.Id % 2 == 0) {
                            if (item.category === category || category === 'All') return true;
                        }
                        return false;


                    }).map((project) => (


                        <img data-aos-once="true" data-aos-mirror="true" data-aos="fade-right" onClick={() => handlePreview(project.Id)} className='portfolio-projects-item' src={project.image}></img>

                    ))}


                </div>
            </div>


        </div>


    );

}

export default Portfolio;