import './css/Consultation.css'
import { Link } from 'react-router-dom';

const Consultation = () => {

    return (

        <div className="Consultation">
            
            <h1 data-aos-mirror="true" data-aos="fade-up" className='consultation-title'>Request a Consultation</h1>
            
            <p data-aos-mirror="true" data-aos="fade-up" className='section_content'>Get Your Consultation Now and Get The Respond With a Few Days.
            </p>

            <Link to='/consultation' className='consultation-button'>Request a consultation</Link>
        
        </div>
    );
}

export default Consultation;