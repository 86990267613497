import './css/Contact.css'
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaClock } from "react-icons/fa";
import { useState } from 'react';






const Contact = () => {

    const [previewConfirmation, setConfirmationPreview] = useState(false);
    const handleConfirmationPreview = () => {

        setConfirmationPreview(!previewConfirmation);

    }

    let confirmationMessage =

        <div className='dark'>

            <div className='confirmation-message'>

                <p className='confirm-message'>Thank You For Your Contacting Us </p>

                <button onClick={() => handleConfirmationPreview()} className='continue-button'>
                    Continue
                </button>

            </div>
        </div>




    return (

        <div className="Contact">

            {previewConfirmation && confirmationMessage}


            <div className='title'>

                <div style={{ backgroundColor: "black" }} class="horizontal-line"></div>
                <p data-aos-mirror="true" data-aos="slide-right" className='title-name'>Contact Us</p>

            </div>
            <h1 style={{ width: "100%" }} data-aos-mirror="true" data-aos="slide-right" className='section_slogan'>Get in Touch.</h1>
            {/* <p data-aos-mirror="true" data-aos="fade" className='section_content'> It is a versatile technology that can learn from vast amounts of data, making it an invaluable tool for businesses and researchers alike. From natural language processing to computer vision
            </p> */}

            <div data-aos-once="false" data-aos-mirror="true" data-aos="slide-right" className='contact_links'>
                <div className='contact-link'>


                    <FaLocationDot />
                    <div className='link-info'>
                        <h6>Our Location</h6>
                        <p>Madinaty - B11</p>
                    </div>

                </div>

                <div className='contact-link'>

                    <MdOutlinePhoneInTalk />
                    <div className='link-info'>
                        <h6>Phone Number</h6>
                        <p>01117113447</p>
                    </div>

                </div>

                <div className='contact-link'>

                    <MdEmail />
                    <div className='link-info'>
                        <h6>Email</h6>
                        <p>info@icreate.vip</p>
                    </div>

                </div>

                <div className='contact-link'>

                    <FaClock />
                    <div className='link-info'>
                        <h6>Working Hours</h6>
                        <p>From 10 am till 6 pm</p>
                    </div>

                </div>
            </div>




            <div data-aos-once="false" data-aos-mirror="true" data-aos="fade" className='contact-us-form'>

                <input className='contact-us-form-input' placeholder='Your Name'></input>
                <input className='contact-us-form-input' placeholder='Your Email'></input>
                <input className='contact-us-form-input' placeholder='Your Phone'></input>

                <textarea className='contact-us-form-message' placeholder='Your Message*'></textarea>
                <button onClick={() => handleConfirmationPreview()} className='contact-us-form-send-button'>Send Message</button>

            </div>

        </div>
    );

}

export default Contact;