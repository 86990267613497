import React, { useRef, useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { Cloud } from '@react-three/drei';
import "./css/Space.css"

export default function Space() {
  

  return (
    <></>
    // <Canvas className='Space' style={{ zIndex: -1, width: '100%', height: '100vh', position: 'fixed' }}>
    //   {/* <Sparkles  position={[0,0,5]}  speed={0.05} opacity={0.5} count={100}  /> */}
     

    // </Canvas>
    
  );
}
