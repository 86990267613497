import Header from "../components/Header";
import Blog from "../components/Blog";
import Footer from '../components/Footer';
import Planet from '../components/Planet'
import Space from "../components/Space";
import transition from "../transition";


const blog = () => {

    return (
        <div className="Blog_page" >
            <div style={{ opacity:"0.45" ,  height: "100vh", width: "100%", zIndex: -3, position: "fixed" }}><Space /> </div>
            <head>
                <title>Blog - iCreate</title>
            </head>

            <Header color={"white"}></Header>

            <Blog></Blog>


            <Footer color={"rgb(172, 172, 172"} ></Footer>




        </div>

    );
}

export default transition(blog);