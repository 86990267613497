import Header from "../components/Header";
import Consultation_And_Form from "../components/Consultation_And_Form";
import Footer from '../components/Footer';
import Blob from "../components/Blob";
import transition from "../transition";

const consultation = () => {

    return (
        <div >
        <Blob/>
        <div className="Cosultation_page"></div>
            <head>
                <title>Consultation - iCreate</title>
            </head>


            <div className="blackless">
                <Header color={"white"}></Header>

                <Consultation_And_Form></Consultation_And_Form>

                <Footer color={"rgb(172, 172, 172"} ></Footer>

            </div>




        </div>

    );
}

export default transition(consultation);