import Header from "../components/Header";
import Job from "../components/Job";
import Footer from '../components/Footer';
import './css/gradients.css'
import Planet from '../components/Planet'
import Space from "../components/Space";


const job = () => {

    return (
        <div className="Careers_page" >
            <div style={{ opacity:"0.45" ,  height: "100vh", width: "100%", zIndex: -3, position: "fixed" }}><Space /> </div>
            <head>
                <title>Careers - iCreate</title>
            </head>

            <Header color={"white"}></Header>

            <Job></Job>

            <Footer color={"rgb(172, 172, 172"} ></Footer>



        </div>

    );
}

export default job;