import { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './css/Careers.css'
import jobs from './jobs-test.json'
import { FaLocationDot } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";



const Careers = () => {

    const navigate = useNavigate();

    const navigateURL = (id) => {

        // navigate(`/job/${id}`, { replace: false }
        // );

    }


    const [selectedJob, setJob] = useState(0);
    const [previewApplyForm, setPreview] = useState(false);
    const [previewConfirmation, setConfirmationPreview] = useState(false);

    const [category, setCategory] = useState('All');


    const handlePreview = (id) => {

        setPreview(!previewApplyForm);
        setJob(id - 1);

    }

    const handleConfirmationPreview = () => {

        setPreview(false);
        setConfirmationPreview(!previewConfirmation);

    }

    const handleCatg = (catg) => {

        setCategory(catg);
    }


    let jobForm =

        <div className='dark'>

            <div className='jobForm'>

                <IoCloseOutline size={28} style={{ cursor: "pointer", marginLeft: "auto" }} onClick={() => handlePreview(1)}></IoCloseOutline>

                <p className='input-title'>Email Address</p>
                <input className='jobForm-input' placeholder='Enter Email Adderess'></input>

                <p className='input-title'>Mobile Phone</p>
                <input className='jobForm-input' placeholder='Enter Mobile Number '></input>

                <p className='input-title'>Resume</p>
                <input type="file" accept=".pdf" className='jobForm-input' placeholder='Insert Resume '></input>

                <button onClick={() => handleConfirmationPreview()} className='jobForm-apply-button'>
                    Apply
                </button>

            </div>
        </div>



    let confirmationMessage =

        <div className='dark'>

            <div className='confirmation-message'>

                <p className='confirm-message'>You have submitted your Application successfully!</p>

                <button onClick={() => handleConfirmationPreview()} className='continue-button'>
                    Continue
                </button>

            </div>
        </div>

    return (

        <div className="Careers">

            {previewApplyForm && jobForm}

            {previewConfirmation && confirmationMessage}


            <div className='title'>

                <div style={{ backgroundColor: "white" }} class="horizontal-line"></div>
                <p data-aos-mirror="true" data-aos="slide-right" className='title-name'>Careers</p>


            </div>
            <div className='centered'>
                <h1 data-aos-mirror="true" data-aos="slide-right" className='section_slogan'>Looking for a job.</h1>

                <div data-aos-mirror="true" data-aos="zoom-in-up" className='catgs_bar'>

                    <button onClick={() => handleCatg("All")} className='catg'>All</button>
                    <span className='catgs-bar-dash'>\</span>
                    <button onClick={() => handleCatg("Product Design")} className='catg'>Product Design</button>
                    <span className='catgs-bar-dash'>\</span>
                    <button onClick={() => handleCatg("Marketing")} className='catg'>Marketing</button>
                    <span className='catgs-bar-dash'>\</span>
                    <button onClick={() => handleCatg("Development")} className='catg'>Development</button>
                    <span className='catgs-bar-dash'>\</span>
                    <button onClick={() => handleCatg("Content Creation")} className='catg'>Content Creation</button>
                    <span className='catgs-bar-dash'>\</span>
                    <button onClick={() => handleCatg("Sales")} className='catg'>Sales</button>


                </div>
            </div>
            <div className='careers-jobs'>

                {jobs.filter(item => {

                    if (item.category === category || category === 'All') return true;

                    return false;

                }).map((job) => (


                    <div data-aos-once="false" data-aos="slide-right" className='careers-job'>

                        <p onClick={() => navigateURL(job.Id)} className='job-title'>
                            {job.title}
                        </p>

                        <p className='job-description'>
                            {job.description}
                        </p>

                        <div className='job-details'>
                            <div className='job-location'>
                                <FaLocationDot></FaLocationDot>
                                <p>{job.location}</p>
                            </div>
                            <div className='job-type'>
                                <FaClock></FaClock>
                                <p>{job.type}</p>
                            </div>
                        </div>


                        <button onClick={() => handlePreview(job.Id)} className='job-apply-button'>
                            Apply
                        </button>
                    </div>

                ))}

            </div>


        </div>


    );

}

export default Careers;