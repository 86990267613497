import './css/Main_3.css'
import { FaBrain } from 'react-icons/fa';
import { HiOutlineLightBulb } from "react-icons/hi";
import { HiOutlineSquaresPlus } from "react-icons/hi2";
import ReactCardFlip from 'react-card-flip';
import { useState } from "react";


const Main_3 = () => {

    const [isFlippedArray, setIsFlippedArray] = useState([false, false, false]); // Initial state for 3 cards

    const flipCard = (index) => {
        const newFlippedArray = [...isFlippedArray];
        newFlippedArray[index] = !newFlippedArray[index];
        setIsFlippedArray(newFlippedArray);
    }
    return (

        <div className="Main_3 ">

            <div data-aos-mirror="true" data-aos="zoom">
            <ReactCardFlip flipDirection="horizontal" isFlipped={isFlippedArray[0]}>

                <div onClick={()=>flipCard(0)} className='card'>
                    
                    <img src='https://i.postimg.cc/Zn3bhTmf/feature-concept.png' className='card-icon'></img>
                    
                    <h3 className='card-title head_shadow'>Feature Concept</h3>
                
                </div>
                
                <div style={{backgroundColor:"rgba(21, 21, 22, 0.5)"}} onClick={()=>flipCard(0)} className='card'>
                
                <p className='content parag_shadow'>
                    Our feature concept revolves around leveraging the power of social media marketing. We help businesses reach their target audience through strategic campaigns on platforms like Facebook, Instagram, and Twitter. By analyzing data and metrics, we optimize these campaigns to maximize engagement and drive conversions.
                </p>
                </div>
            
            </ReactCardFlip>
            </div>

            

            <div data-aos-mirror="true" data-aos="zoom">
            <ReactCardFlip flipDirection="horizontal" isFlipped={isFlippedArray[1]}>

                <div onClick={()=>flipCard(1)} className='card'>
                    
                <img src='https://i.postimg.cc/Kz1cJPHh/big-ideas.png' className='card-icon'></img>

<h3 className='card-title head_shadow'>Big Ideas.</h3>
                
                </div>
                
                <div  style={{backgroundColor:"rgba(21, 21, 22, 0.767)"}} onClick={()=>flipCard(1)} className='card'>
                
                <p className='content parag_shadow' >
                    We strive to achieve a significant digital transformation in the field of e-marketing. We offer innovative and advanced solutions that combine technology and creativity to deliver exceptional results for our clients. We believe in intelligent personalization, data analysis, multi-channel marketing, and adaptive thinking. 
                </p>
                </div>
            {/* These are the big ideas that drive us to lead in the digital marketing world and create a real impact for our clients. */}
            </ReactCardFlip>
            </div>

            <div data-aos-mirror="true" data-aos="zoom">
            <ReactCardFlip flipDirection="horizontal" isFlipped={isFlippedArray[2]}>

                <div onClick={()=>flipCard(2)} className='card'>
                    
                <img src='https://i.postimg.cc/ncbVGQKN/creativity.png' className='card-icon'></img>
                <h3 className='card-title head_shadow'>Creativity.</h3>
                
                </div>
                
                <div  style={{backgroundColor:"rgba(21, 21, 22, 0.767)"}} onClick={()=>flipCard(2)} className='card'>
                
                <p className='content parag_shadow'>
                    We believe that creativity is the driving force behind successful e-marketing campaigns. Our feature concept focuses on crafting unique brand storytelling, utilizing cutting-edge visual and multimedia solutions, generating outside-the-box campaign ideas, and designing user-centric experiences. By leveraging creativity, we aim to deliver captivating and memorable marketing experiences that make a lasting impact.
                </p>
                </div>
            
            </ReactCardFlip>
            </div>


          


        </div>


    );

}

export default Main_3;