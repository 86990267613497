import "./css/Services_info.css";
import { LiaPencilAltSolid } from "react-icons/lia";
import { MdDeveloperMode } from "react-icons/md";
import { TbBrandDatabricks } from "react-icons/tb";
import { BsChatSquareText } from "react-icons/bs";
import { FaWordpress } from "react-icons/fa";
import { PiDevices } from "react-icons/pi";
import { TbWorldSearch } from "react-icons/tb";
import { TfiWrite } from "react-icons/tfi";
import { CiShoppingCart } from "react-icons/ci";
import { SiWoocommerce } from "react-icons/si";
import { BiLogoCodepen } from "react-icons/bi";
import { FaLaptopCode } from "react-icons/fa";
import { FcAdvertising } from "react-icons/fc";
import { TbDeviceDesktopCheck } from "react-icons/tb";
import { SlPencil } from "react-icons/sl";
import { BsBrush } from "react-icons/bs";
import { MdOutlinePlayCircle } from "react-icons/md";
import { TiVideo } from "react-icons/ti";
import { RiNftLine } from "react-icons/ri";
import { PiVirtualRealityLight } from "react-icons/pi";
import { TbAugmentedReality } from "react-icons/tb";
import { TbPlugConnected } from "react-icons/tb";
import { GiArtificialIntelligence } from "react-icons/gi";
import { FaBusinessTime } from "react-icons/fa6";
import { IoIosCodeWorking } from "react-icons/io";
import { SiSimpleanalytics } from "react-icons/si";
import { GrMoney } from "react-icons/gr";
import { RiLoopRightLine } from "react-icons/ri";
import { TbSettingsAutomation } from "react-icons/tb";





const Services_info = () => {

    return (


        <div className="Services_header">

            <div className='title'>

                <div style={{ backgroundColor: "black" }} class="horizontal-line"></div>
                <p data-aos-mirror="true" data-aos="slide-right" className='title-name'>Services</p>

            </div>
            <h1 data-aos-mirror="true" data-aos="slide-right" className='section_slogan'>We do everything.</h1>

            <p data-aos-mirror="true" data-aos="fade" className='section_content'>

                We operate with start-ups to large operation companies by providing them with full digital marketing services.
            </p>


            <div className="Services_info">


                <div className="services_col_1">
                    <div data-aos-once="false" data-aos-mirror="true" data-aos="fade" className="service">
                        <LiaPencilAltSolid className="service_logo"></LiaPencilAltSolid>
                        <p className="service_title">
                            Website and Application Development
                        </p>

                        <p className="service_content">

                            We believe in web designing in customer in mind,
                            and it`s that philosophy that`s` helped us make a
                            name for ourselves as a trusted web devilopment firm.
                            Our team understands what it takes to develop effective services and create
                            a growth-mind website, and we`re happy to deliver these services to you.

                        </p>

                        <p className="service_content">
                            Regardless of whether you`re a service-based  business or an e-commerce retailer
                            selling goods in an online store, our full stack marketing team will go above and beyond
                            to create a website that combines UI/UX
                            ,search engine optimization, intuitive, and responsive design, and technical prowess
                        </p>

                        <p className="service_content">

                            Avoid expensive mistakes and hire iCreate to redesign your website now. Websites
                            should undergo a meaningful
                            upgrade every 3-4 years, the fear of change or delay can end up being a costly mistake.
                        </p>

                        <p className="service_title">
                            <br></br><br></br><br></br><br></br>Custom Built Website
                        </p>

                        <p className="service_content">
                            Specializing in Wordpress websites and e-commerce solutions,
                            our primary focus is always usability, growth, responsive design, and a user experience.
                        </p>

                        <div className="service_icons">

                            <div className="service_object">

                                <FaWordpress className="serivce_icon"></FaWordpress>

                                <p className="service_object_name">
                                    Wordpress <br></br> Development

                                </p>

                            </div>

                            <div className="service_object">

                                <PiDevices className="serivce_icon"></PiDevices>

                                <p className="service_object_name">
                                    Responsive <br></br> Design

                                </p>

                            </div>


                            <div className="service_object">

                                <TbWorldSearch className="serivce_icon"></TbWorldSearch>

                                <p className="service_object_name">
                                    SEO <br></br> Optimization

                                </p>

                            </div>

                        </div>


                        <div className="service_icons">

                            <div className="service_object">

                                <TfiWrite className="serivce_icon"></TfiWrite>

                                <p className="service_object_name">
                                    Custom Graphic <br></br> Design

                                </p>

                            </div>

                            <div className="service_object">

                                <CiShoppingCart className="serivce_icon"></CiShoppingCart>

                                <p className="service_object_name">
                                    Shopify

                                </p>

                            </div>


                            <div className="service_object">

                                <SiWoocommerce className="serivce_icon"></SiWoocommerce>

                                <p className="service_object_name">
                                    Woocommerce

                                </p>

                            </div>

                        </div>

                    </div>

                    <div data-aos-once="false" data-aos-mirror="true" data-aos="fade" className="service">
                        <TbBrandDatabricks className="service_logo"></TbBrandDatabricks>
                        <p className="service_title">
                            Branding and Design
                        </p>

                        <p className="service_content">

                            One of our core missions is to become part of many success stories. First impressions are everything. and our team will help YOu make a great One. Successful brands know how to stand out from the crowd even if they're selling a good or service that's like something else on the market. they understand hOw to master bold design that helps them connect with their customers and showcase their brand Identitystrongly and reliably. Here at ICreate. we believe a great brand is one of the most valuable assets out there. and our design and branding services seek to help you transform your brand from good to stunning. Through beautiful design. we help you stand out to customers. build trust. and promote brand recognition across the board. No job is too large or too small for our full.stack marketing team - come to us for infographict digital and print material. mobile' responsive sites. comprehensive re-brands. and more.

                        </p>



                        <p className="service_title">
                            Branding Steps
                        </p>

                        <p className="service_content">
                            Specializing in WordPress websites and e.commerce solutions. our primary focus is always usabiliti, growth. responsive design, and a killer user experience.
                        </p>

                        <div className="service_icons">

                            <div className="service_object">

                                <BiLogoCodepen className="serivce_icon"></BiLogoCodepen>

                                <p className="service_object_name">
                                    Logo and <br></br> Branding

                                </p>

                            </div>

                            <div className="service_object">

                                <FaLaptopCode className="serivce_icon"></FaLaptopCode>

                                <p className="service_object_name">
                                    Software <br></br> Design

                                </p>

                            </div>


                            <div className="service_object">

                                <FcAdvertising className="serivce_icon"></FcAdvertising>

                                <p className="service_object_name">
                                    Creative <br></br> Advertising

                                </p>

                            </div>

                        </div>


                        <div className="service_icons">

                            <div className="service_object">

                                <TbDeviceDesktopCheck className="serivce_icon"></TbDeviceDesktopCheck>

                                <p className="service_object_name">
                                    Custom Web <br></br> Design

                                </p>

                            </div>

                            <div className="service_object">

                                <SlPencil className="serivce_icon"></SlPencil>

                                <p className="service_object_name">
                                    UI/UX <br></br>Design

                                </p>

                            </div>


                            <div className="service_object">

                                <BsBrush className="serivce_icon"></BsBrush>

                                <p className="service_object_name">
                                    Creating <br></br> Illustrations

                                </p>

                            </div>

                        </div>


                        <div className="service_icons">

                            <div className="service_object">

                                <MdOutlinePlayCircle className="serivce_icon"></MdOutlinePlayCircle >

                                <p className="service_object_name">
                                    Motion <br></br> Graphic

                                </p>

                            </div>

                            <div className="service_object">

                                <TiVideo className="serivce_icon"></TiVideo>

                                <p className="service_object_name">
                                    Video <br></br>Editing

                                </p>

                            </div>


                            <div className="service_object">

                                <RiNftLine className="serivce_icon"></RiNftLine>

                                <p className="service_object_name">
                                    NFTs

                                </p>

                            </div>

                        </div>

                    </div>


                </div>

                <div className="services_col_2">

                    <div data-aos-once="false" data-aos-mirror="true" data-aos="fade" className="service">
                        <MdDeveloperMode className="service_logo"></MdDeveloperMode>
                        <p className="service_title">
                            Software Development
                        </p>

                        <p className="service_content">

                            iCreate Is a premier custom software development and Quality assurance company delivering complex worldciass software solutions on a variety of technology platforms to clients ranging from Fortune 500 companies to dynamic startups.

                        </p>

                        <p className="service_content">
                            - For Startups at iCreate, we are problem solvers above all, and we love new challenges. Thus we love working with startups.
                            <br></br> - Entrepreneurs have brilliant ideas, and iCreate has the necessary technical expertise and rigorous processes to help transform ideas into a service or a product and deliver it to the market.
                            <br></br> - iCreate has worked with startups at just about every stage of growth and funding.
                            <br></br> - We have a thorough understanding of critical milestones that must be accomplished to advance to the next stage of growth
                            <br></br> - Our commitment tocontemporary agile practices helps to tackle development goats in a time-sensitive manner delivering market-ready solutions to our chants.
                        </p>



                        <p className="service_title">
                            Innovative Features

                        </p>

                        <p className="service_content">
                            Specializing in Wordpress websites and e-commerce solutions,
                            our primary focus is always usability, growth, responsive design, and a user experience.
                        </p>

                        <div className="service_icons">

                            <div className="service_object">

                                <PiVirtualRealityLight className="serivce_icon"></PiVirtualRealityLight>

                                <p className="service_object_name">
                                    Virtual <br></br> Reality <br></br> VR

                                </p>

                            </div>

                            <div className="service_object">

                                <TbAugmentedReality className="serivce_icon"></TbAugmentedReality>

                                <p className="service_object_name">
                                    Augmented <br></br> Reality <br></br> AR

                                </p>

                            </div>


                            <div className="service_object">

                                <TbPlugConnected className="serivce_icon"></TbPlugConnected>

                                <p className="service_object_name">
                                    Mixed <br></br> Reality <br></br> MR

                                </p>

                            </div>

                            <div className="service_object">

                                <GiArtificialIntelligence className="serivce_icon"></GiArtificialIntelligence>

                                <p className="service_object_name">
                                    Artificial <br></br> Intelligence <br></br> Solutions <br></br>AI

                                </p>

                            </div>

                        </div>




                    </div>

                    <div data-aos-once="false" data-aos-mirror="true" data-aos="fade" className="service">
                        <BsChatSquareText className="service_logo"></BsChatSquareText>
                        <p className="service_title">
                            Strategy and Consulting
                        </p>

                        <p className="service_content">

                            Let us help you analyze your business and identify areas that can be easily outsourced or automated

                        </p>




                        <div className="service_icons_special">

                            <div className="service_object">

                                <FaBusinessTime className="serivce_icon"></FaBusinessTime>

                                <p style={{ width: "80%" }} className="service_object_name">
                                    Business Development
                                    <br></br>
                                    At iCreate. we want to help You forget the comprehensive and beneficial partnerships you need to build your brand.
                                </p>


                            </div>

                              <div className="service_object">

                                <IoIosCodeWorking className="serivce_icon"></IoIosCodeWorking>

                                <p style={{ width: "80%" }} className="service_object_name">
                                    Product Development
                                    <br></br>
We have an extensive background in both outsourcing and automation, and we are uniquely qualified to help you identify the service providers that will work best for your brand.                                 </p>


                            </div>


                            <div className="service_object">

                                <SiSimpleanalytics className="serivce_icon"></SiSimpleanalytics>

                                <p style={{ width: "80%" }} className="service_object_name">
                                    Competitive Analysis
                                    <br></br>
                                    We form strategic Partnershipswen indusfirkeding service and solutions An:MOM which can help you fulfill your company's needs.
                                </p>


                            </div>


                        </div>

                        <div className="service_icons_special">
                        
                              <div className="service_object">

                                <GrMoney className="serivce_icon"></GrMoney>

                                <p style={{ width: "80%" }} className="service_object_name">
                                    Financial Valuation
                                    <br></br>
                                    Bring us ywr pain points. and well come back with comprehensive solutions designed to Provide precisely the functionality you need to thrive.                                </p>


                            </div>

                            <div className="service_object">

                                <TbSettingsAutomation className="serivce_icon"></TbSettingsAutomation>        
                                <p style={{ width: "80%" }} className="service_object_name">
                                    Automation Services 
                                    <br></br>
                                    Provide better service and grow Intelligently with the help of our strategic partnership and automation services. 

                                   </p>


                            </div>

                        </div>


                        <p style={{marginTop:"60px"}} className="service_title">
                            Performance Analytics

                        </p>

                        <p className="service_content">
                            Stop struggling to figure out what wed% and what doesn't for your company. Instead. hire a skilled fuu.stack marketing team to provide the performance analytics services you need. Here at iCreate.we seek to put the power of data back into your hands. When you come to Create for performance analytics services, you'll get Powerful, flexible, native analytics you can rely on at every stage of your business. Let us help you 0M/0 your business forward and accelerate your results at every turn. Come to us for best practice KPls, real time visibIlily, a comprehensive analytics hub, and spotlight, text analytic". inform analytics. and much, much more.
                        </p>


                    </div>

                </div>
            </div>
        </div>
    )

}


export default Services_info;