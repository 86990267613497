import { useState } from 'react';
import './css/Blog.css'
import posts from './blog-test.json'
import { Route, Routes, useNavigate } from 'react-router-dom';




const Blog = () => {

    const navigate = useNavigate(); 

    const navigateURL = (id) =>{

        navigate(`/post/${id}`, { replace: false }
);

    }

    const [category, setCategory] = useState('All');


    const handleCatg = (catg) => {

        setCategory(catg);
    }


    return (

        <div className="Blog">



            <div className='title'>

                <div style={{ backgroundColor: "black" }} class="horizontal-line"></div>
                <p data-aos-mirror="true" data-aos="slide-right" className='title-name'>Blog</p>



            </div>

            <h1 data-aos-mirror="true" data-aos="slide-right" className='section_slogan'>Explore and Learn.</h1>

            <div data-aos-mirror="true" data-aos="zoom-in-up" className='catgs_bar'>

                <button onClick={() => handleCatg("All")} className='catg'>All</button>
                <span className='catgs-bar-dash'>\</span>
                <button onClick={() => handleCatg("Web and App Development")} className='catg'>Web and App Development</button>
                <span className='catgs-bar-dash'>\</span>
                <button onClick={() => handleCatg("Branding & Identity")} className='catg'>Branding & Identity</button>
                <span className='catgs-bar-dash'>\</span>
                <button onClick={() => handleCatg("Software Development")} className='catg'>Software Development</button>
                <span className='catgs-bar-dash'>\</span>
                <button onClick={() => handleCatg("Marketing")} className='catg'>Marketing</button>

            </div>

            <div className='blog_posts'>


                {posts.filter(item => {

                    
                        if (item.category === category || category === 'All') return true;
                    
                    return false;


                }).map((post) => (


                    <div data-aos-once="false" data-aos-mirror="true" data-aos="fade-right" className='post'>

                        <img onClick={()=>navigateURL(post.Id)} className='post-img' src='https://images.prodia.xyz/64d7b1ed-0ac9-4d56-9c60-12ded01e8c14.png'></img>
                        <p className='post-type'>
                           {post.category}
                        </p>

                        <p className='post-title'>
                        {post.title}
                        </p>

                        <p className='post-descriptiom'>
                        {post.description}
                        </p>

                        {/* <div className='post-author'>

                            <img className='post-author-pic' src={post.author_pic} />

                            <div className='post-author-info'>

                                <p className='post-author-name'>
                                    {post.author_name}
                                </p>
                                <p className='post-author-date'>
                                    {post.date}
                                </p>


                            </div>

                        </div> */}




                    </div>

                ))}










            </div>

        </div>
    );

}

export default Blog;