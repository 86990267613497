import Header from "../components/Header";
import Careers from "../components/Careers";
import Footer from '../components/Footer';
import Blob from "../components/Blob";
import transition from "../transition";


const careers = () => {
// style={{background: "linear-gradient(90deg, rgba(13,10,23,1) 32%, rgba(190,10,177,1) 100%)"}}
    return (
        <div className="Careers_page">
        <Blob/>
            <head>
                <title>Careers - iCreate</title>
            </head>


            <div className="blackless">
                <Header color={"white"}></Header>

                <Careers></Careers>

                <Footer color={"rgb(172, 172, 172"} ></Footer>

            </div>


           

        </div>

    );
}

export default transition(careers);